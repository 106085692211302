import React, { useEffect, useState } from "react"
import { get } from "lodash"
import { Div, Text } from "atomize"
import CardHeader from "../common/CardHeader"
import Swiper from "react-id-swiper"
import ImageLazyLoad from "../common/molecules/ImageLazyLoad"
import VideoOrImage from "../common/molecules/VideoOrImage"

const params = {
  slidesPerView: 1,
  slidesPerGroupSkip: 1,
  effect: "fade",
  allowTouchMove: true,
  noSwiping: true,
  mousewheel: {
    invert: false,
    forceToAxis: true,
  },
}

const ProductCarousel = ({
  toggleSelectedSlide,
  selectedSlide,
  onSwiperInit,
  selectedVariant,
}) => {
  return (
    <>
      <Div
        rounded="lg"
        overflow="hidden"
        bg="yellow"
        shadow="1"
        border="1px solid"
        borderColor="yellowPink"
      >
        <CardHeader
          padding="13px 10px"
          heading={selectedVariant?.carousel?.title}
          image={selectedVariant?.carousel?.icon?.asset?.url}
          textSize="paragraph"
        />
        <Div
          p="8px 0"
          bg="white"
          border={{ y: "1px solid" }}
          borderColor="yellowPink"
          textSize="paragraph1"
          textAlign="center"
        >
          {get(selectedVariant, `carousel.assets.${selectedSlide}.caption`)}
        </Div>
        <Div d={{ lg: "flex" }}>
          {/*---- ON DESKTOP ----*/}
          <Div d={{ xs: "none", lg: "block" }} w="6.25rem" pos="relative">
            <Div
              pos="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              p="10px"
              overflow="auto"
              className="product-images"
            >
              {get(selectedVariant, "carousel.assets", []).map((asset, id) => {
                const isSelected = id === selectedSlide

                return (
                  <Div
                    key={id}
                    cursor="pointer"
                    onClick={() => {
                      toggleSelectedSlide(id)
                      const replayBtn = document.querySelector(
                        `.replaybtn${id}`
                      )
                      replayBtn && replayBtn.click()
                    }}
                    rounded="5px"
                    overflow="hidden"
                    m={{ b: "1.25rem" }}
                  >
                    <Div
                      bg={isSelected ? "gray" : "transparent"}
                      transition=".4s all ease-in-out"
                      p="10px"
                      pos="relative"
                    >
                      <ImageLazyLoad
                        rounded="lg"
                        pausable={true}
                        isSelected={isSelected}
                        bgImg={asset?.image?.asset?.url}
                        bgPos="center"
                        bgSize="cover"
                        p={{ b: "100%" }}
                      />
                    </Div>
                    <Text
                      textColor={isSelected ? "white" : "blue"}
                      bg={isSelected ? "blue" : "transparent"}
                      p="3px 5px"
                      textSize="paragraph"
                      textAlign="center"
                      transition=".4s all ease-in-out"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {asset.caption}
                    </Text>
                  </Div>
                )
              })}
            </Div>
          </Div>

          {/* Main Swiper */}
          <Div flexGrow="1 1 100%" overflow="hidden">
            <Div className="pdp-swiper">
              <Div bg="gray">
                <Swiper {...params} getSwiper={swiper => onSwiperInit(swiper)}>
                  {get(selectedVariant, "carousel.assets", []).map(
                    (asset, id) => {
                      return (
                        <Div key={id}>
                          <VideoOrImage
                            bgPos="center"
                            bgSize="cover"
                            product={true}
                            file={
                              asset?.video?.asset?.url ||
                              asset?.image?.asset?.url
                            }
                            id={id}
                          />
                        </Div>
                      )
                    }
                  )}
                </Swiper>
              </Div>
            </Div>
          </Div>
        </Div>

        {/*-----
            *****  FOR MOBILE *****
        -----*/}

        <Div
          // flexGrow="1 1 20%"
          d={{ xs: "flex", lg: "none" }}
          p="10px"
          overflow="scroll"
        >
          {get(selectedVariant, "carousel.assets", []).map((asset, id) => {
            const isSelected = id === selectedSlide

            return (
              <Div
                key={id}
                cursor="pointer"
                onClick={() => {
                  toggleSelectedSlide(id)
                  const replayBtn = document.querySelector(`.replaybtn${id}`)
                  replayBtn && replayBtn.click()
                }}
                rounded="sm"
                overflow="hidden"
                m={{ r: { xs: "1.25rem", lg: "0" } }}
                maxW="5rem"
                minW="80px"
              >
                <Div
                  bg={isSelected ? "gray" : "transparent"}
                  transition=".4s all ease-in-out"
                  p="10px"
                  pos="relative"
                >
                  <ImageLazyLoad
                    rounded="lg"
                    bgImg={asset?.image?.asset?.url}
                    bgPos="center"
                    bgSize="cover"
                    p={{ b: "100%" }}
                    isSelected={isSelected}
                    pausable={true}
                  />
                </Div>
                <Text
                  m={{ b: { lg: "1.25rem" } }}
                  textColor={isSelected ? "white" : "black"}
                  bg={isSelected ? "blue" : "transparent"}
                  p="5px"
                  textSize="paragraph"
                  // maxW="4.25rem"
                  transition=".4s all ease-in-out"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {asset.caption}
                </Text>
              </Div>
            )
          })}
        </Div>
      </Div>
    </>
  )
}

export default ProductCarousel
