import axios from "axios"
import { get } from "lodash"
import React, { useState, useEffect } from "react"

const generateFetchUrl = ({
  productId,
  after,
  before,
  sort = "created_at",
  order = "desc",
  perPage = 6,
}) => {
  if (after) {
    return `https://api.juniphq.com/v1/product_reviews?filter[product_remote_id]=${productId}&page[after]=${after}&include=customer&sort[field]=${sort}&sort[order]=${order}&page[size]=${perPage}`
  }

  if (before) {
    return `https://api.juniphq.com/v1/product_reviews?filter[product_remote_id]=${productId}&page[before]=${before}&include=customer&sort[field]=${sort}&sort[order]=${order}&page[size]=${perPage}`
  }

  return `https://api.juniphq.com/v1/product_reviews?filter[product_remote_id]=${productId}&include=customer&sort[field]=${sort}&sort[order]=${order}&page[size]=${perPage}`
}

const useReviews = ({ productId, after, before, sort, order, perPage }) => {
  const [data, setData] = useState(null)
  const [meta, setMeta] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  /**
   * fetches reviews
   */
  const fetchReviews = async () => {
    setLoading(true)

    const url = generateFetchUrl({
      productId,
      after,
      before,
      sort,
      order,
      perPage,
    })

    try {
      let response = await axios.get(url, {
        headers: {
          "Junip-Store-Key": "ip5LCVzAChK33V3aQZ1QFt3U",
        },
      })

      const reviews = get(response, "data.product_reviews")
      const meta = get(response, "data.meta")

      setData(reviews)
      setMeta(meta)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }

  useEffect(() => {
    if (productId) {
      fetchReviews()
    }
  }, [after, before, productId, sort, order])

  return { data, error, loading, meta }
}

export default useReviews
