import axios from "axios"
import ls from "local-storage"

/**
 * update/downvote review
 *
 * @param {*} param0
 */
export const voteReview = ({
  review,
  type = "id",
  alreadyVoted = false,
  setReview,
}) => {
  if (alreadyVoted) {
    type = type === "up" ? "down" : "up"
  }

  return axios
    .put(
      `https://api.juniphq.com/v1/product_reviews/${review.id}/${type}_vote`,
      {},
      {
        headers: {
          "Junip-Store-Key": "ip5LCVzAChK33V3aQZ1QFt3U",
        },
      }
    )
    .then(response => {
      updateReviewCount({ review, type, setReview })
      updateReviewsInLocalStorage(review.id, type, alreadyVoted)
    })
}

/**
 * update review count
 *
 * @param {*} review
 * @param {*} type
 * @param {*} setReview
 */
export const updateReviewCount = ({ review, type, setReview }) => {
  switch (type) {
    case "up":
      setReview({ ...review, up_vote_count: review.up_vote_count + 1 })
      break

    case "down":
      setReview({ ...review, down_vote_count: review.down_vote_count + 1 })
      break
  }
}

/**
 * here  tracking the which reviews are upvotes/downvoted
 * through localStorage. If user has upvoted, remove it from downReviews
 * array and vice versa
 */
export const updateReviewsInLocalStorage = (
  reviewId,
  type,
  alreadyVoted = false
) => {
  const upvotes = ls("upReviews") || []
  const downvotes = ls("downReviews") || []

  switch (type) {
    case "up":
      const downvoteIndex = downvotes.findIndex(value => value == reviewId)

      if (downvoteIndex > -1) {
        downvotes.splice(downvoteIndex, 1)
      }

      if (alreadyVoted === false) {
        upvotes.push(reviewId)
      }

      ls("downReviews", downvotes)
      ls("upReviews", upvotes)
      break

    case "down":
      const upvoteIndex = upvotes.findIndex(value => value == reviewId)

      if (upvoteIndex > -1) {
        upvotes.splice(upvoteIndex, 1)
      }

      if (alreadyVoted === false) {
        downvotes.push(reviewId)
      }

      ls("downReviews", downvotes)
      ls("upReviews", upvotes)

      break
  }
}
