import React from 'react'

export const trackProductPage = (product) => {
  if(process.env.NODE_ENV === "production"){
    window.freshpaint.removeEventProperty('productPageView')
    window.freshpaint.track(
      'productPageView',
      {'productData': product.data}
    );
  }
}

export const trackAddToBag = (product, variant, count) => {
  if(process.env.NODE_ENV === "production"){
    window.freshpaint.removeEventProperty('addToBag')
    window.freshpaint.track(
      'addToBag',
      {
        productId: product.sanityId,
        content_type: 'product_group',
        content_name: variant.title,
        value: variant.price,
        discountedPrice: variant.discountedPrice,
        quantity: count,
        currency: 'USD',
      }
    );
  }
}
