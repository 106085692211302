import React from "react"
import { Div, Text } from "atomize"

export default function RatingStar({ m, rating, width, height }) {
  return (
    <Div pos="relative" m={m}>
      <Div d="flex" textSize="paragraph1">
        {[0, 1, 2, 4, 5].map(item => (
          <Div textSize="paragraph1" key={`ratingStar${item}`}>
            <svg
              width={width}
              height={height}
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.40742 1.35229L7.69386 3.99973L7.80929 4.23728L8.07057 4.27584L10.963 4.70272L8.86479 6.77998L8.68033 6.96261L8.72355 7.21856L9.21719 10.1418L6.64294 8.76719L6.40742 8.64144L6.17191 8.76719L3.59766 10.1418L4.0913 7.21856L4.13452 6.96261L3.95006 6.77998L1.85188 4.70272L4.74428 4.27584L5.00556 4.23728L5.12099 3.99973L6.40742 1.35229Z"
                fill="white"
                stroke="#283377"
              />
            </svg>
          </Div>
        ))}
      </Div>
      <Div
        d="flex"
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w={Math.floor(rating / 0.5) * 0.5 * 20 + "%"}
        flexWrap="no-wrap"
        overflow="hidden"
      >
        {[0, 1, 2, 4, 5].map(item => (
          <Div textSize="paragraph1" key={`ratingStarFilled${item}`}>
            <svg
              width={width}
              height={height}
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.40742 0.208252L8.14357 3.7812L12.0257 4.35415L9.21657 7.1353L9.87972 11.0624L6.40742 9.20825L2.93513 11.0624L3.59828 7.1353L0.78913 4.35415L4.67128 3.7812L6.40742 0.208252Z"
                fill="#283377"
              />
            </svg>
          </Div>
        ))}
      </Div>
    </Div>
  )
}
