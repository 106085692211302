import { get } from "lodash"
import React, { useState } from "react"
import { Div, Text, Row, Col, Image } from "atomize"
import VideoCard from "../components/common/VideoCard"
import PdpModal from "../components/common/molecules/PdpModal"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"

export default function ModuleProductInfo({ title, asset, product }) {
  const [modal, showModal] = useState(false)
  const [image, variantImage] = useState(null)

  const openModal = data => {
    showModal(true)
    variantImage(data)
  }

  const shouldShowSupplementSection = () => {
    const variantImages = product.variants.map(variant =>
      get(variant, "supplementFacts.asset.url")
    )
    return variantImages.some(item => item !== undefined)
  }

  const shouldShowCOAsSection = () => {
    const variantImages = product.variants.map(variant =>
      get(variant, "latestCoas.asset.url")
    )
    return variantImages.some(item => item !== undefined)
  }

  return (
    <Div
      p={{ t: { xs: "6.25rem", lg: "13.75rem" } }}
      className="bgblocks productinfo"
      data-bg="#FDEEFF"
    >
      <WebsiteContainer>
        <PdpModal
          isOpen={modal}
          onClose={() => showModal(false)}
          variantImage={image}
        />
        <Row>
          <Col size={{ xs: "12", lg: "7" }}>
            <Div
              m={{ l: { lg: "1.5rem" } }}
              rounded="lg"
              border="1px solid"
              borderColor="yellowPink"
              shadow="1"
            >
              <VideoCard bold="bold" {...asset} mobRatio="1:1" />
            </Div>
          </Col>
          <Col size={{ xs: "12", lg: "5" }} style={{ margin: "auto" }}>
            <Div
              m={{
                l: { xs: "0", lg: "6.25rem" },
                t: { xs: "2.5rem", lg: "0" },
              }}
              maxW={{ xs: "100%", lg: "21rem" }}
            >
              <Text
                textTransform="uppercase"
                m={{ b: "1.875rem" }}
                textWeight="bold"
                textSize="display1"
                fontFamily="secondary"
                textAlign={{ xs: "center", lg: "left" }}
              >
                {title}
              </Text>

              {shouldShowSupplementSection() && (
                <Div
                  p={{ y: "1.875rem" }}
                  border={{ y: "1px solid" }}
                  borderColor="borderColor"
                >
                  <Text
                    textSize="title1"
                    textAlign={{ xs: "center", lg: "left" }}
                  >
                    Supplement Facts
                  </Text>
                  <Div
                    d="flex"
                    m={{ t: "1.25rem" }}
                    flexWrap="wrap"
                    justify={{ xs: "center", lg: "left" }}
                  >
                    {product.variants.map(
                      variant =>
                        get(variant, "supplementFacts.asset.url") && (
                          <Div
                            d="flex"
                            align="center"
                            p="11px 1.25rem"
                            border="1px solid"
                            borderColor="blue"
                            rounded="20px"
                            m={{ r: "12px", b: "12px" }}
                            cursor="pointer"
                            onClick={() =>
                              openModal(
                                get(variant, "supplementFacts.asset.url")
                              )
                            }
                          >
                            {get(variant, "icon.asset.url") ? (
                              <Image
                                src={get(variant, "icon.asset.url")}
                                h="1rem"
                                w="1rem"
                                m={{ r: "5px" }}
                              />
                            ) : (
                              ""
                            )}
                            <Text
                              textTransform="uppercase"
                              textWeight="bold"
                              style={{ lineHeight: "14px" }}
                              fontFamily="secondary"
                            >
                              {variant.variantTitle}
                            </Text>
                          </Div>
                        )
                    )}
                  </Div>
                </Div>
              )}

              {shouldShowCOAsSection() && (
                <Div
                  p={{
                    t: "1.875rem",
                    b: !shouldShowSupplementSection() && "1.875rem",
                  }}
                  border={{
                    y: !shouldShowSupplementSection() && "1px solid",
                  }}
                  borderColor="borderColor"
                >
                  <Text
                    textSize="title1"
                    textAlign={{ xs: "center", lg: "left" }}
                  >
                    Latest Lab Reports
                  </Text>
                  <Div
                    d="flex"
                    m={{ t: "1.25rem" }}
                    flexWrap="wrap"
                    justify={{ xs: "center", lg: "left" }}
                  >
                    {product.variants.map(
                      variant =>
                        get(variant, "latestCoas.asset.url") && (
                          <Div
                            d="flex"
                            align="center"
                            p="11px 1.25rem"
                            border="1px solid"
                            borderColor="blue"
                            rounded="20px"
                            m={{ r: "12px", b: "12px" }}
                            cursor="pointer"
                            onClick={() => {
                              get(variant, "latestCoas.asset.url").endsWith(
                                ".pdf"
                              )
                                ? window.open(
                                    get(variant, "latestCoas.asset.url")
                                  )
                                : openModal(
                                    get(variant, "latestCoas.asset.url")
                                  )
                            }}
                          >
                            {get(variant, "icon.asset.url") ? (
                              <Image
                                src={get(variant, "icon.asset.url")}
                                h="1rem"
                                w="1rem"
                                m={{ r: "5px" }}
                              />
                            ) : (
                              ""
                            )}
                            <Text
                              textTransform="uppercase"
                              textWeight="bold"
                              style={{ lineHeight: "14px" }}
                              fontFamily="secondary"
                            >
                              {variant.variantTitle}
                            </Text>
                          </Div>
                        )
                    )}
                  </Div>
                </Div>
              )}
            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}
